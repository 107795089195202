import React, { useContext } from "react";
import AppContext from "../../state/AppContext";
import constants from "../../../constants";

function BackButton(props) {
    const state = useContext(AppContext);

    function handleStateUpdate() {
        if (state.currentPage === constants.PAGES.DELIVERY_PAGE) {
            state.updateCurrentDelivery({});
        }
        state.updateCurrentPage(props.previousPage);
    }

    //back button has a dynamic classname, when it's clicked, it goes to hidden. This should later on also be disabled so it's not clicked on again.
    return (
        <div>
            <button
                onClick={() => {
                    handleStateUpdate();
                }}
                className={`Dark-blue-button ${props.activateDummyLoadingPage === true ? `Hidden-button` : ` `}`}
            >
                Back
            </button>
        </div>
    );
}

export default BackButton;
