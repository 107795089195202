import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import AWS from 'aws-sdk';

AWS.config.region = constants.AWS_REGION;
AWS.config.update({
	region: constants.AWS_REGION,
});

/**
 * This function used to call the backend to check the unlock status of the car.
 * Now we simply wait for the driver to confirm
 * return the output to carLockStatus
 * @returns {any[]}
 * @constructor
 */
function GetLockStatus() {
	const state = useContext(AppContext);
	const [carLockStatus, setCarLockStatus] = React.useState();
	const [carLockStatusLoading, setCarLockStatusLoading] = React.useState(true);

	const TIMEOUT_DELAY = process.env.REACT_APP_TIMEOUT_DELAY || getLockType(state);

	function confirmFinish() {
		setCarLockStatus(constants.PAGES.CHECK_LOCK);
		setCarLockStatusLoading(false);
	}

	React.useEffect(() => {
		setTimeout(confirmFinish, TIMEOUT_DELAY);
	}, []);

	return [carLockStatus, carLockStatusLoading];
}

export default GetLockStatus;

function getLockType(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		10000 : 3000;
}