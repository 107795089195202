import React, { useContext } from "react";
import DeliveryDetails from "./DeliveryDetails";
import constants from "../../constants.js";
import AppContext from "../state/AppContext";
import ClickableDetails from "./ClickableDetails";
import "./DeliveryList.css";

/**
 * Display a list of today's Deliveries
 * @returns {*}
 * @constructor
 */
function DeliveryList() {
    const state = useContext(AppContext);
    console.log("state inside DeliveryList: ", state);
    console.log("currD inside DeliveryList: ", state.currentDelivery);

    if (state.deliveries.length < 1) {
        return (
            <header className="App-header">
                <div className="No-deliveries-div">
                    <div className="Message no-vehicles">No deliveries today</div>
                </div>
            </header>
        );
    } else {
        /* 	Loop over today's deliveries and display a card for each one
         * 	Use formatting to display if parcel is active, complete, errored
         **/
        const approvedDeliveries = state.deliveries.filter((delivery) => {
            return delivery.deliveryStatus === constants.DELIVERY_STATUS.APPROVED;
        });
        const completedDeliveries = state.deliveries.filter((delivery) => {
            return delivery.deliveryStatus === constants.DELIVERY_STATUS.COMPLETED;
        });
        const failedDeliveries = state.deliveries.filter((delivery) => {
            return delivery.deliveryStatus === constants.DELIVERY_STATUS.FAILED;
        });

        return (
            <div>
                <div>
                    {approvedDeliveries.map((item) => (
                        <ClickableDetails delivery={item} key={item.barcode} />
                    ))}
                </div>
                <div>
                    {completedDeliveries.map((item) => (
                        <DeliveryDetails className="completed" key={item.barcode} delivery={item} />
                    ))}
                </div>
                <div>
                    {failedDeliveries.map((item) => (
                        <DeliveryDetails className="failed" key={item.barcode} delivery={item} />
                    ))}
                </div>
            </div>
        );
    }
}

export default DeliveryList;
