import React, {useContext} from "react";
import GlobalState from "../state/GlobalState";
import constants from "../../constants";
import PageRouter from "../pages/PageRouter";
import NavigationBarMain from "../display/NavigationBarMain";

function StateCheck(props) {

	console.log('Class: StateCheck, Line 9 (props): ', props.loggedInUser);
    let initialState = calculateInitialState(props.deliveries, props.loggedInUser);

    return (
        <div className="App-container">
            <GlobalState initialState={initialState}>
                <NavigationBarMain />
                <PageRouter />
            </GlobalState>
        </div>
    );
}

export default StateCheck;

function isActive(deliveries, i) {
	return constants.DELIVERY_STATUS.APPROVED !== deliveries[i].deliveryStatus &&
		constants.DELIVERY_STATUS.COMPLETED !== deliveries[i].deliveryStatus &&
		constants.DELIVERY_STATUS.FAILED !== deliveries[i].deliveryStatus;
}

function calculateInitialState(deliveries, user) {
    console.log(deliveries);
    let initialState = {
        currentPage: constants.PAGES.DELIVERY_LIST,
        currentUser: user,
        currentDelivery: {},
        deliveries: deliveries,
    };
	console.log("initialState is :");
	console.log(initialState);
    if (deliveries.length > 0) {
        // For each delivery do we have one that is active?
        for (let i = 0; i < deliveries.length; i++) {
            console.log(deliveries[i].deliveryStatus);
            if (isActive(deliveries, i)) {
            	console.log('Class: calculateInitialState, Line 45 (set ACTIVE): ', deliveries[i].id);
                initialState.currentDelivery = deliveries[i];
                initialState.currentPage = deliveries[i].deliveryStatus;
                break;
            }
        }
    }

    return initialState;
}
