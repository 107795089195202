import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar } from 'react-bootstrap';
import './NavigationBar.css';
import HelpButton from './HelpButton';
import SignOutModal from './SignOutModal';
import { LoggedInContext } from '../state/LoggedInContext';

function NavigationBar() {
    const { isSignedIn } = useContext(LoggedInContext);
    console.log('isSignedIn is: ', isSignedIn);
    return (
        <div>
            {isSignedIn ? null : (
                <Navbar
                    className="Navbar"
                    collapseOnSelect
                    expand="lg"
                    variant="dark"
                >
                    <Navbar.Brand href="/">
                        <img
                            alt=""
                            src={require('../../assets/EvriLogo.png')}
                            width="150px"
                            height="100%"
                            className="Navbar-brand d-inline-block align-center nav-logo"
                        />
                    </Navbar.Brand>

                    <Nav className="mr-auto"></Nav>
                </Navbar>
            )}
        </div>
    );
}

export default NavigationBar;
