import React, { useContext } from "react";
import AppContext from "../../state/AppContext";
import constants from "../../../constants";
import "./Error.css";
import DeliveryDetails from "../../display/DeliveryDetails";
import axios from "axios";
const DELIVERY_SERVICE_URL = process.env.REACT_APP_DELIVERY_SERVICE_URL;

/**
 * Used for locking and unlocking errors
 * @returns {*}
 * @constructor
 */
function Error() {
    const state = useContext(AppContext);
    function updateDeliveryStatus() {
        const payload = {
            status: "FAILED",
        };
        const httpConfig = {
            method: "POST",
            data: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
            },
        };
        const URL = `${DELIVERY_SERVICE_URL}/${state.currentDelivery.id}`;
        axios(URL, httpConfig).catch((error) => {
            console.log("error in updateDeliveryStatus is :", error);
        });
    }

    console.log("ERROR state: ", state);
    function failDelivery() {
        updateDeliveryStatus();
        state.updateCurrentDeliveryStatus(constants.DELIVERY_STATUS.FAILED);
        state.updateCurrentDelivery({});
        state.updateCurrentPage(constants.PAGES.DELIVERY_LIST);
    }

    let message = state && state.hasOwnProperty('errorMessage') ? state.errorMessage : "There was an error.";

    console.log("state in error", state);
    return (
        <div>
            <DeliveryDetails delivery={state.currentDelivery} />
            <div className="Error">
                <img className="Alert" src={require("../../../assets/alert.svg")} alt="" />
                <p className="Error-message">{message}</p>
                <p className="Error-message">Please continue with the other deliveries.</p>
            </div>
            <div className="Error-footer">
                <button className="Dark-blue-button" onClick={failDelivery}>
                    Back to parcel list
                </button>
            </div>
        </div>
    );
}

export default Error;
