import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import DriverStepList from "../display/DriverStepList";
import DeliveryDetails from "../display/DeliveryDetails";
import GetLabelFromState from "../state/GetLabelFromState";

function PlaceInBoot() {
    const state = useContext(AppContext);

    function handleStateUpdate() {
        state.updateCurrentPage(constants.PAGES.CLOSE_AND_LOCK);
    }

    const label = GetLabelFromState(state);
    return (
        <div>
            <DeliveryDetails delivery={state.currentDelivery} />
            <DriverStepList />
            <div className="Main-footer">
                <button className="Dark-blue-button" onClick={handleStateUpdate}>
                    The parcel is in the {label}
                </button>
            </div>
        </div>
    );
}

export default PlaceInBoot;