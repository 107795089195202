import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import "./HelpButton.css";

function HelpButton() {
    const state = useContext(AppContext);

    function handleStateUpdate() {
        if (state.currentPage !== constants.PAGES.HELP) {
            console.log("helpButton state is: ", state);
            state.updatePreviousPage(state.currentPage);
            state.updateCurrentPage(constants.PAGES.HELP);
        }
    }
    return (
        <div>
            <button className="Help-button" onClick={handleStateUpdate}>
                Help
            </button>
        </div>
    );
}

export default HelpButton;
