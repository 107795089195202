const constants = {

	DEFAULT_CAR_MAKE: "FORD",
	FORD_AV_TRIAL_MAKE: "FORDAV",
	VW_MAKE: "Volkswagen",
	DEFAULT_PARCEL_BOX_MAKE: "IPARCELBOX",

    LABELS: {
		LOCK: "Lock",
		LOCKING: "Locking",
		UNLOCKING: "Unlocking",
		UNLOCK: "Unlock",
		PLACE_IN_BOOT: "PLACE",
    },
    DRIVER_STEPS: [
		"Unlock the vehicle",
		"Place in vehicle",
		"Lock the vehicle",
		"Check the vehicle",
    ],
    WELCOME_MESSAGE: "Manifest List",
    PAGES: {
		DELIVERY_LIST: "DELIVERY_LIST",
		DELIVERY_PAGE: "DELIVERY_PAGE",
		BARCODE_SCANNER: "BARCODE_SCANNER",
		SCANNED: "SCANNED",
		UNLOCKING: "UNLOCKING",
		CHECK_UNLOCK: "CHECK_UNLOCK",
		UNLOCKED: "UNLOCKED",
		PLACE_IN_BOOT: "PLACE_IN_BOOT",
		CLOSE_AND_LOCK: "CLOSE_AND_LOCK",
		LOCKING: "LOCKING",
		LOCKED: "LOCKED",
		CHECK_LOCK: "CHECK_LOCK",
		LOCK_CONFIRMED: "LOCK_CONFIRMED",
		NO_DELIVERIES: "NO_DELIVERIES",
		HELP: "HELP",
		ERROR: "ERROR",
    },
    DELIVERY_STATUS: {
		APPROVED: "APPROVED",
		COMPLETED: "COMPLETED",
		FAILED: "FAILED",
		UNLOCKING: "UNLOCKING",
		UNLOCKED: "UNLOCKED",
		LOCKING: "LOCKING",
		LOCKED: "LOCKED",
    },
  
    HELP_PAGES: {
      DELIVERY_LIST: [
        // {
        //   problem: "Parcel status incorrect",
        //   advice: "Contact the support team",
        //   modalButtonText: "Call support",
        // },
        // {
        //   problem: "Parcel not listed",
        //   advice: "If you are able to, deliver to the recipient's door",
        //   modalButtonText: "Deliver to door",
        //   eventType: "FAILED",
        //   failType: "MANIFEST_ISSUE",
        // },
        {
          problem: "Call support",
          advice: "Contact the support team",
          modalButtonText: "Call support",
        },
      ],
      DELIVERY_PAGE: [
        // {
        //   problem: "Can't find the car",
        //   advice: "If you are able to, deliver to the recipient's door",
        //   modalButtonText: "Deliver to door",
        //   failType: "CAR_NOT_FOUND",
        //   eventType: "FAILED",
        // },
        // {
        //   problem: "Map not loading",
        //   advice:
        //     "Check internet connection and continue to make the delivery if you can see the car",
        //   modalButtonText: "Continue delivery",
        //   failType: "MAP_NOT_LOADING",
        //   eventType: "FAILED",
        // },
        // {
        //   problem: "The car is inaccessible",
        //   advice: "If you are able to, deliver to the recipient's door",
        //   modalButtonText: "Deliver to door",
        //   failType: "CAR_NOT_ACCESSIBLE",
        //   eventType: "FAILED",
        // },
        {
          problem: "Call support",
          advice: "Contact the support team",
          modalButtonText: "Call support",
        },
      ],
      BARCODE_SCANNER: [
        {
          problem: "Scanner not opening",
          advice: "Allow browser to access camera",
          modalButtonText: "Give camera access",
          failType: "TECHNICAL_ERROR",
          eventType: "NO_SCANNER",
        },
        {
          problem: "Barcode not working",
          advice: "Contact the support team",
          modalButtonText: "Call support",
          failType: "PARCEL_WONT_SCAN",
          eventType: "FAILED",
        },
        {
          problem: "Call support",
          advice: "Contact the support team",
          modalButtonText: "Call support",
        },
      ],
      UNLOCKING: [
        {
          problem: "Car unlocked but app shows error",
          advice: "Lock car and deliver to door",
          modalButtonText: "Lock car",
          failType: "CAR_HAS_UNLOCKED",
          eventType: "UNLOCKING",
        },
        {
          problem: "Call support",
          advice: "Contact the support team",
          modalButtonText: "Call support",
        },
      ],
  
      UNLOCKED: [
        {
          problem: "Car wont unlock",
          advice: "Wait an extra 15 seconds and if no change, deliver to door",
          modalButtonText: "Deliver to door",
          failType: "CAR_WONT_UNLOCK",
          eventType: "UNLOCKED",
        },
  
        {
          problem: "Call support",
          advice: "Contact the support team",
          modalButtonText: "Call support",
        },
      ],

      PLACE_IN_BOOT: [
        {
          problem: "Parcel won't fit",
          advice: "Lock car and deliver to door",
          modalButtonText: "Lock car",
          failType: "NO_SPACE_IN_CAR",
          eventType: "FAILED",
        },
        {
          problem: "The car is inaccessible",
          advice: "If you are able to, deliver to the recipient's door",
          modalButtonText: "Deliver to door",
          failType: "CAR_NOT_ACCESSIBLE",
          eventType: "FAILED",
        },
  
      ],
  
      LOCKED: [
        {
          problem: "Car not locked",
          advice: "Contact the support team",
          modalButtonText: "Call support",
          eventType: "NOT_LOCKED",
          failType: "CAR_WONT_LOCK",
        },
        {
          problem: "Call support",
          advice: "Contact the support team",
          modalButtonText: "Call support",
        },
      ],
    },
    DEFAULT_ERROR_MESSAGE: {
		problem: "Call support",
		advice: "Contact the support team",
		modalButtonText: "Call support",
	},
    BARCODE_ERROR_MESSAGE:
      "Incorrect barcode - please try again or return to previous page",
    SCANDIT_LICENCE:
      "AUHfFjSXFEx0PbR7rxzCMvkhqda+RY6QtmJlCvAfccEBfu+PmnICCXlGdK29Tee0I3GFbRl4roSDbLl1ZgskPytgBZh8LE4f/kF14+938a65RB" +
		"Z+qFOQzBpmXrPKAzUM6ksPozp2HTYXWJ3aZncRKstFh50gT9e2jSybNcU6Za6/Dk8s5i6WoisMXP7eGbuuAbmtqH4n9DcHESOG5W3scbhqyb" +
		"uxWm38nAqFFrmL6ev/nY2MAGDBt9IvVcwv7PUd0MxkfaHpDntKvcd8rAvRJJ36bJ7ElB0k1qfbfQcs+GEHrU2eCfnTcNsVAj9M2PleAXCqyI" +
		"vQ7gnwaQ7RgNiSxEuWertKrntWh3PsN6jUbjxHMMR5keBtTzjzdV3+2ZvtH9MRf0D4kY2tXK6t97BP9MWpfnWSAOBy/kUYD8LkiHyxGmKTjz" +
		"drmP5QHiBwwq0J7ZWRpkubAsbsF5Yl0QYnWzloihojCSWq+7f5prHmuJ0nuY7rBH8nQzzjI/EeGoESCeRMk1LKnTmP7AA54/CcrGQTdSB8I6" +
		"VfUY4q+u4yE8ulg4+Z7SvKxZWZjB8ARMYWPHdOJzxI4/es7qxTO1teoGtvSVWuYveRi2kqQ7twEkC2w5DENyApHgMdbzXzUE3wAYcTkadZnq" +
		"BDzzoiTmIi7xx8+pUul+joFk325ALeNKgIc24Nn40QE9EuwMPM0XcdtPoqf2lpGRJQqDquB7loZez0knMR8YsjbAibdh4LoHuNsnGoO7uoFq" +
		"qcpaJrEuHKnDvDiSeLAjl5IdWFTVk7W1X1DKZoabXppVyQr3yFOC02PZBj8TP7kybqjaP10lOC9BvBpqQ9SR8Agv6nZ0ksd1Gz+X+0T5+F6DE=", // this is prod licence and only works at hermesincardelivery.com
      // TODO switch licences on env == PROD
      AWS_REGION: "eu-west-1",
      DEFAULT_PARCEL_BOX: "IPARCELBOX"
  };
  
  export default constants;
