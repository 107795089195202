import React, { useContext } from "react";
import "./CheckLogin.css";
import "@aws-amplify/ui/dist/style.css";
import "bootstrap/dist/css/bootstrap.css";
import Landing from "./Landing";
import { LoggedInContext } from "./components/state/LoggedInContext";
import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

Amplify.configure({
	Auth: {
		region: 'eu-west-1',
		userPoolId: "eu-west-1_pI06awREw",
		userPoolWebClientId: "7au990codbi37b9lue482t30o7"
	}
});

function CheckLogin() {
    const { setIsSignedIn } = useContext(LoggedInContext);

    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setUser(authData);
            setAuthState(nextAuthState);
        });
    }, []);

    if (user && user.attributes) {
        console.log("user: ", user);
        setIsSignedIn(user.attributes.email);
    }

    return authState === AuthState.SignedIn && user ? (
        <div>
            <Landing loggedInUser={user.attributes.email} />
        </div>
    ) : (
        <AmplifyAuthenticator>
            <AmplifySignIn slot="sign-in" hideSignUp />
        </AmplifyAuthenticator>
    );
}

export default CheckLogin;
