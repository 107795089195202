import React, { useContext } from "react";
import AppContext from "../../state/AppContext";
import constants from "../../../constants";

import axios from "axios";
const LOCK_URL = process.env.REACT_APP_VMS_LOCK_URL;

function UnlockButton(props) {
    const state = useContext(AppContext);
    const deliveryId = state.currentDelivery.id;
	console.log('Class: UnlockButton, Line 15 (S.CD.ID): ', deliveryId);
    const vehicleId = getVehicle(state);

	const [buttonDisabled, setButtonDisabled] = React.useState(false);

    function unlockCar() {
		console.log('Class: unlockCar, Line 15 (S.CD.ID): ', deliveryId);

		const params = {
			method: "POST",
			data: JSON.stringify({
				refresh_token: getToken(state),
				make: getMake(state),
				id: deliveryId,
				courierEmail: state.currentDelivery.courierEmail
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}
		};
		console.log('Class: unlockCar, Line 32 (params): ', params);
		axios(`${LOCK_URL}/${vehicleId}/unlock`, params)
		.then((res) => {
			console.log("Unlock api success, res", res.data);
			state.updateCurrentCommandId(res.data.commandId);
			console.log("state in unlock button after response: ", state.currentCommandId);
			state.updateCurrentDeliveryStatus(constants.DELIVERY_STATUS.UNLOCKING);
			state.updateCurrentPage(constants.PAGES.UNLOCKING);
		})
		.catch((e) => {
			console.log("Unlock api error: ", e);
			state.updateCurrentDeliveryStatus(constants.DELIVERY_STATUS.FAILED);
			state.updateCurrentPage(constants.PAGES.ERROR);
		});
	}

    return (
        <div>
            <button
                className={`Dark-blue-button ${props.activateDummyLoadingPage === true ? `Hidden-button` : ` `}`}
                onClick={() => {
					setButtonDisabled(true);
                    unlockCar();
                    props.setActivateDummyLoadingPage(true);
                }}
				disabled={buttonDisabled}>
                Unlock
            </button>
        </div>
    );
}

export default UnlockButton;

function getToken(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.refreshToken :
		state.currentDelivery.parcelbox.refreshToken;
}

function getVehicle(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.vehicleId :
		state.currentDelivery.parcelbox.refreshToken;
}

function getMake(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.make :
		constants.DEFAULT_PARCEL_BOX_MAKE;
}