import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import DriverStepList from "../display/DriverStepList";
import DeliveryDetails from "../display/DeliveryDetails";
import axios from "axios";

const DELIVERY_SERVICE_URL = process.env.REACT_APP_DELIVERY_SERVICE_URL;

function CheckLock() {
    const state = useContext(AppContext);

    function updateDeliveryStatus(status) {
        const payload = {
            status: status,
        };
        const httpConfig = {
            method: "POST",
            data: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
            },
        };
        const URL = `${DELIVERY_SERVICE_URL}/${state.currentDelivery.id}`;
        return axios(URL, httpConfig).catch((error) => {
            console.log("error in updateDeliveryStatus is :", error);
        });
    }

    async function handleStateUpdate(status) {
		const response = await updateDeliveryStatus(status);
		console.log('Class: handleStateUpdate, Line 33 (RESP): ', response);
        state.updateCurrentDeliveryStatus(status);
        state.updateCurrentPage(constants.PAGES.DELIVERY_LIST);
    }

    return (
        <div>
            <DeliveryDetails delivery={state.currentDelivery} />
            <DriverStepList />

            <div className="Main-footer-checkLockFlow">
				<button className="Red-button" onClick={() => {
					handleStateUpdate(constants.DELIVERY_STATUS.FAILED); }}>
					No
				</button>

				<button className="Hidden-button" onClick={() => {
					handleStateUpdate(constants.DELIVERY_STATUS.COMPLETED); }}>
				</button>

				<button className="Green-button" onClick={()=> {
					handleStateUpdate(constants.DELIVERY_STATUS.COMPLETED);}}>
					Yes
				</button>
            </div>
        </div>
    );
}

export default CheckLock;