import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import DeliveryDetails from "../display/DeliveryDetails";
import RefreshLocation from "../display/RefreshLocation";
import GetCarGPS from "../api/GetCarGPS";
import Loading from "../display/Loading";
import BackButton from "../display/buttons/BackButton";
import Alert from "./error_pages/Alert";
import MapContainer from "../display/MapContainer";
import MessageModal from "../display/MessageModal";
import OpenScannerButton from "../display/buttons/OpenScannerButton";
import constants from "../../constants";

/**
 * Responsible for displaying the delivery details
 * initially there are only two types of delivery: In Car and to iParcelBox
 * @returns {*}
 * @constructor
 */
function DeliveryPage() {
    const state = useContext(AppContext);
    const [showModal, setShowModal] = React.useState(false);
    const [refreshButtonClicked, setRefreshButtonClicked] = React.useState(0);
    const [carGPS, courierGPS, loadingCarGPS, apiError] = GetCarGPS(refreshButtonClicked);

    function onRefreshButtonClick() {
        setRefreshButtonClicked(refreshButtonClicked + 1);
    }

	// const label = state.currentDelivery.car.make === constants.DEFAULT_PARCEL_BOX ? "Box" : "Car";
	const label = getLabel(state);

    let mapSnippet;
	if (loadingCarGPS) {
		mapSnippet = <Loading />;
	} else {
		if (apiError) {
			console.log('Class: DeliveryPage, Line 30 (error): ', apiError);
			mapSnippet = <Alert message={apiError} />;
		} else {
			if (!carGPS.isCourierInsideGeoFence) {
				console.log('Class: DeliveryPage, Line 34 (Geo): ', carGPS);
				mapSnippet = <Alert message={carGPS.message} />;
			} else {
				mapSnippet = <MapContainer label={label} carGPS={carGPS} courierGPS={courierGPS} />;
			}
		}

    }

	return (
        <div>
            <DeliveryDetails delivery={state.currentDelivery} />
            <RefreshLocation onRefreshButtonClick={onRefreshButtonClick} loadingCarGPS={loadingCarGPS} />
            <MessageModal showModal={showModal} hideModal={() => setShowModal(false)} />
            {mapSnippet}
            <div className="Main-footer">
                <OpenScannerButton disabled={carGPS && !carGPS.isCourierInsideGeoFence}
								   isCourierInsideUnlock={carGPS && carGPS.isCourierInsideUnlock}
								   setShowModal={setShowModal} />
                <BackButton previousPage={constants.PAGES.DELIVERY_LIST} />
            </div>
        </div>
    );
}

export default DeliveryPage;

function getLabel(state) {
	const make = state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.make : constants.DEFAULT_CAR_MAKE;
	return make.toUpperCase() === constants.DEFAULT_PARCEL_BOX_MAKE ? "Box" : "Car";
}