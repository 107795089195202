import React from "react";
import "./MessageModal.css";

function MessageModal(props) {
    const showHideClassName = props.showModal ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <img className="Modal-Alert" src={require("../../assets/alert.svg")} alt="" />
                <p className="Modal-message"> {props.message ? props.message : "You must be closer to the vehicle to unlock it"}</p>
                <button className="Light-grey-button  Message-modal-button" onClick={props.hideModal}>
                    Ok
                </button>
            </section>
        </div>
    );
}

export default MessageModal;
