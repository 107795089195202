import React from "react";

import "./Error.css";

function Alert(props) {
    // const state = useContext(AppContext);
    console.log("Error component props.message: ", props.message);

    let message = props.message ? props.message : "Ooops there was an error";

    return (
        <div>
            <div className="Error">
                <img className="Alert" src={require("../../../assets/alert.svg")} alt="" />
                <p className="Error-message">{message}</p>
                {props.button ? props.button : null}
            </div>
        </div>
    );
}

export default Alert;
