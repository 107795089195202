import React, { useState } from "react";
import { LoggedInContext } from "./components/state/LoggedInContext";
import "./App.css";
import "@aws-amplify/ui/dist/style.css";
import "bootstrap/dist/css/bootstrap.css";
import NavigationBarSignIn from "./components/display/NavigationBarSingIn";
import CheckLogin from "./CheckLogin";

function App() {
    const [isSignedIn, setIsSignedIn] = useState();
    return (
        <>
            <LoggedInContext.Provider value={{ isSignedIn, setIsSignedIn }}>
                <NavigationBarSignIn />
                <CheckLogin />
            </LoggedInContext.Provider>
        </>
    );
}

export default App;
