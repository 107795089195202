import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import axios from "axios";
import constants from "../../constants";
const GPS_URL = process.env.REACT_APP_VMS_LOCATION_URL;
/**
 * call Hermes Backend for to gather car GPS from Ford API
 *   "longitude": "-0.4336217",
 *   "latitude": "53.6845433",
 */

function GetCarGPS(refreshButtonClicked) {
    const state = useContext(AppContext);
    const [error, setError] = React.useState();
    const [carGPS, setCarGPS] = React.useState();
    const [courierGPS, setCourierGPS] = React.useState();
    const [loadingCarGPS, setLoadingCarGPS] = React.useState(true);

    React.useEffect(() => {
        console.log("running useeffect");

        function getDeviceLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(fetchGPS);
            } else {
                console.log("Geolocation is not supported by this browser.");
                setCarGPS("Geolocation not allowed");
                setLoadingCarGPS(false);
            }
        }

        const address = {
            address1: state.currentDelivery.houseNumber + " " + state.currentDelivery.streetName,
            address2: "",
            city: state.currentDelivery.townOrCity,
        };

        function fetchGPS(courierPosition) {
            console.log("courierPosition: ", courierPosition);
            const vehicleId = getVehicle(state);
            const payload = {
                make: getMake(state),
                proximityType: "GEO_FENCE",
				courierLatitude: courierPosition.coords.latitude,
				courierLongitude: courierPosition.coords.longitude,
                address: address,
                refresh_token: getToken(state),
				id: state.currentDelivery.id
            };
            const httpConfig = {
                method: "POST",
                data: JSON.stringify(payload),
                headers: {
                    "Content-Type": "application/json",
                },
            };

            axios(`${GPS_URL}/${vehicleId}/location`, httpConfig)
                .then((res) => {
                    console.log("RES in GetCarGPS is: ", res);
                    setCarGPS(res.data);
                    setCourierGPS(courierPosition.coords);
                    setLoadingCarGPS(false);
                })
                .catch((error) => {
                    console.log("error in GetCarGPS is :", error);
                    setError("Service is down");
                    setLoadingCarGPS(false);
                });
        }

        getDeviceLocation();
        setLoadingCarGPS(true);
    }, [refreshButtonClicked, state]);

    return [carGPS, courierGPS, loadingCarGPS, error];
}

export default GetCarGPS;

function getToken(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.refreshToken :
		state.currentDelivery.parcelbox.refreshToken;
}

function getVehicle(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.vehicleId :
		state.currentDelivery.parcelbox.refreshToken;
}

function getMake(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.make :
		constants.DEFAULT_PARCEL_BOX_MAKE;
}