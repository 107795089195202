import React, { useContext, useState } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import "./HelpButtonList.css";
import HelpPageModal from "./HelpPageModal";
import axios from "axios";
const DELIVERY_SERVICE_URL = process.env.REACT_APP_DELIVERY_SERVICE_URL;

const HelpButtonList = () => {
    const state = useContext(AppContext);
    const [helpModal, setHelpModal] = useState(false);
    const [selectedButton, setSelectedButton] = useState();
    const [message, setMessage] = useState();
    const handleClose = () => setHelpModal(false);

    let helpTopicList = "";
    let placeholder = "type here...";
    let textButtonLabel = "Save and continue";

    if (constants.HELP_PAGES[state.previousPage]) {
        //checks to see if the current page is covered by the list in constants.js
        helpTopicList = constants.HELP_PAGES[state.previousPage];
    } else {
        // generic case for when particular page isn't covered by the HELP_PAGES in the constants list
        helpTopicList = [
            {
                problem: "Report an issue",
                advice: "Contact the support team",
                modalButtonText: "Call team",
            },
        ];
    }

	function handleChange(event) {
		setMessage(event.target.value);
	}

    function handleSend() {
		failCurrentDelivery();
		state.updateCurrentDeliveryStatus(constants.DELIVERY_STATUS.FAILED);
		state.updateCurrentDelivery({});
		state.updateCurrentPage(constants.PAGES.DELIVERY_LIST);
	}

	function failCurrentDelivery() {
		const payload = {
			statusCode: 900,
			subject: "eventType",
			eventType: "eventType",
			failReason: message,
			failType: "failType",
			status: constants.DELIVERY_STATUS.FAILED
		};
		const httpConfig = {
			method: "POST",
			data: JSON.stringify(payload),
			headers: {
				"Content-Type": "application/json",
			},
		};
		console.log("Class: failCurrentDelivery, Line 69 (cfg): ",  );
		const URL = `${DELIVERY_SERVICE_URL}/${state.currentDelivery.id}`;
		axios(URL, httpConfig).catch((error) => {
			console.log("error in updateDeliveryStatus from HelpLoggingButton:", error);
		});
	}


	return (
        <div>
			<HelpPageModal
				show={helpModal}
				close={handleClose}
				chosenOption={selectedButton}
				previousPage={state.previousPage}
			/>

			{/* Removed most buttons by commenting them out in constants */}
			<div className="HelpTextInput">

				<label htmlFor="amount">Unable to deliver reason</label>

				<input placeholder={placeholder}
					   onChange={(value) => handleChange(value)}
					   className="form-control buttonListItem genericButton"
					   id="amount" name="amount" type="textarea" />
				<button
					key=""
					className="buttonListItem genericButton"
					onClick={(event) => {
						handleSend(event)
					}}
				>
					{textButtonLabel}
				</button>
			</div>
            <div className="HelpButtonList">
                {helpTopicList.map((topic) => {
                    return (
                        <button
                            key={`${topic.problem}`}
                            className="buttonListItem genericButton"
                            onClick={() => {
								console.log('Class: HelpButtonList, Line 53 (helpModal): ', helpModal);
                                setHelpModal(true);
                                setSelectedButton(topic);
                            }}
                        >
                            {topic.problem}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default HelpButtonList;

function getToken(state) {
	console.log("Class: getToken, Line 132 (CD): ", state.currentDelivery);
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.hasOwnProperty('car') ?
		state.currentDelivery.car.refreshToken :
		state.currentDelivery.parcelbox.refreshToken;
}

function getMake(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.make :
		constants.DEFAULT_PARCEL_BOX_MAKE;
}