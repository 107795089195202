import React, {useContext} from "react";

import "./App.css";
import "@aws-amplify/ui/dist/style.css";
import "bootstrap/dist/css/bootstrap.css";
import StateCheck from "./components/state/StateCheck";
import GetTodaysDeliveries from "./components/api/GetTodaysDeliveries";
import Loading from "./components/display/Loading";

function Landing(props) {
    console.log("props in Landing is:", props);

    const [deliveries, loadingDeliveries, error] = GetTodaysDeliveries(props.loggedInUser);
    if (error) {
		return (
			<div className="App">
				<StateCheck deliveries={[]} />
			</div>
		);
    } else if (!loadingDeliveries) {
        return (
            <div className="App">
                <StateCheck deliveries={deliveries} loggedInUser={props.loggedInUser}/>
            </div>
        );
    } else {
        return <Loading />;
    }
}

export default Landing;
