import React, { useContext } from "react";
import constants from "../../constants";
import "./DriverStepList.css";
import AppContext from "../state/AppContext";
import GetLabelFromState from "../state/GetLabelFromState";

/**
 * List of steps for the driver to follow
 * Initially seems better to map an array of steps rather than hard code the steps
 * @returns {*}
 * @constructor
 */
const DriverStepList = () => {
    const state = useContext(AppContext);
    const label = GetLabelFromState(state);
    return (
        <div className="DriverStepListContainer">
            <div className="DriverStepList">
                <div>
                    <li data-testid="ad-list-item-scanned" className={`driverstep unlock ${state.currentPage === constants.PAGES.SCANNED ? `active` : ``}`}>
                        1. Unlock the {label}
                    </li>
					<li data-testid="ad-list-item-scanned" className={`driverstep unlock ${state.currentPage === constants.PAGES.CHECK_UNLOCK ? `active` : ``}`}>
						2. Is the {label} unlocked?
					</li>
                    <li data-testid="ad-list-item-placeInBoot" className={`driverstep place ${state.currentPage === constants.PAGES.PLACE_IN_BOOT ? `active` : ``}`}>
                        3. Place the parcel
                    </li>
                    <li data-testid="ad-list-item-placed" className={`driverstep lock ${state.currentPage === constants.PAGES.CLOSE_AND_LOCK ? `active` : ``}`}>
                        4. Close and lock the {label}
                    </li>
                    <li data-testid="ad-list-item-confirm" className={`driverstep check ${state.currentPage === constants.PAGES.CHECK_LOCK ? `active` : ``}`}>
                        5. Is the {label} locked?
                </li>
                </div>
                <div/>
            </div>
        </div>
    );
};

export default DriverStepList;