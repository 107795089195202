import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import Loading from "../display/Loading";
import GetLockStatus from "../api/GetLockStatus";
import DeliveryDetails from "../display/DeliveryDetails";
import CheckLock from "./CheckLock";

function Locking() {
	const state = useContext(AppContext);
	const [carLockStatus, carLockStatusLoading] = GetLockStatus();
	if (carLockStatusLoading) {
		return (
			<div>
				<DeliveryDetails delivery={state.currentDelivery} />
				<Loading />
				<p className="Lock-status-p">{constants.LABELS.LOCKING}</p>
				<div>
					<div className="Main-footer"/>
				</div>
			</div>
		);
	} else {
		state.updateCurrentPage(constants.PAGES.CHECK_LOCK);
		return <CheckLock />;
	}
}

export default Locking;