import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import TickAnimation from "../display/TickAnimation";
import DeliveryDetails from "../display/DeliveryDetails";

function Unlocked() {
    const state = useContext(AppContext);
    function redirectTimer() {
        console.log("redirectTimer: ", state);
        state.updateCurrentPage(constants.PAGES.PLACE_IN_BOOT);
    }

    setTimeout(redirectTimer, 2000);

    return (
        <div>
            <DeliveryDetails delivery={state.currentDelivery} />
            <TickAnimation />
            <p className="Lock-status-p Fade-in-animation">Unlocked</p>
            <div className="Main-footer"/>
        </div>
    );
}

export default Unlocked;
