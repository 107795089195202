import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./HelpPageModal.css";
import HelpLoggingButton from "../../components/display/buttons/HelpLoggingButton";
import constants from "../../constants";

const HelpPageModal = (props) => {
	const chosenOption = props.hasOwnProperty("chosenOption") && props.chosenOption ?
		props.chosenOption :
		constants.DEFAULT_ERROR_MESSAGE;
    return (
        <div>
            <Modal
                className="HelpPageModal"
                centered
                animation={false}
                show={props.show}
                onHide={props.close}
            >
                <Modal.Header closeButton className="modal-header-colour">
                    {chosenOption.problem}
                </Modal.Header>
                <Modal.Body>{chosenOption.advice}</Modal.Body>
                <Modal.Footer>
                    {chosenOption.advice === "Contact the support team" ? ( //checks if the user selects the call option and produces a special button
                        <div className="modal-footer-button-container">
                            <Button variant="primary" href="tel:+448004643655">
                                {chosenOption.modalButtonText}
                            </Button>

                            {/* {wont display the below button if on the delivery list page} */}
                            {props.previousPage !== "DELIVERY_LIST" ? (
                                <HelpLoggingButton
                                    variant="danger"
                                    close={props.close}
                                    eventType="FAILED"
                                    failType="MANUALLY_FAILED"
                                    reason="Problem occurred that wasn't listed and couldn't be remotely resolved"
                                >
                                    Deliver to door
                                </HelpLoggingButton>
                            ) : null}
                        </div>
                    ) : (
                        <HelpLoggingButton
                            variant="danger"
                            close={props.close}
                            eventType={chosenOption.eventType}
                            failType={chosenOption.failType}
                            reason={chosenOption.problem}
                        >
                            {chosenOption.modalButtonText}
                        </HelpLoggingButton>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default HelpPageModal;
