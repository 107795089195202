import React, { useContext } from "react";
import AppContext from "../../state/AppContext";
import constants from "../../../constants";

function OpenScannerButton(props) {
    const state = useContext(AppContext);

    function openScanner() {
        if (!props.disabled) {
            if (props.isCourierInsideUnlock) {
                state.updatePreviousPage(state.currentPage);
                state.updateCurrentPage(constants.PAGES.BARCODE_SCANNER);
            } else {
                props.setShowModal(true);
            }
        }
    }

    return (
        <button
            disabled={props.disabled} // check if we can open the scanner - maybe hide the button?
            className="Dark-blue-button"
            onClick={openScanner}
        >
            Open Scanner
        </button>
    );
}

export default OpenScannerButton;
