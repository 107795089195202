import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import TickAnimation from "../display/TickAnimation";
import DeliveryDetails from "../display/DeliveryDetails";

function Locked() {
    const state = useContext(AppContext);
    state.updateCurrentDeliveryStatus(constants.DELIVERY_STATUS.LOCKED);
    function redirectTimer() {
        state.updateCurrentPage(constants.PAGES.CHECK_LOCK);
        console.log("redirectTimer: ", state);
    }

    setTimeout(redirectTimer, 2000);

    return (
        <div>
            <DeliveryDetails delivery={state.currentDelivery} />
            <TickAnimation />
            <p className="Lock-status-p Fade-in-animation">Locked</p>
            <div className="Main-footer"/>
        </div>
    );
}

export default Locked;
