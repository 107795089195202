import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import BackButton from "../display/buttons/BackButton";
import DeliveryDetails from "../display/DeliveryDetails";
import HelpButtonList from "../display/HelpButtonList";

function Help() {
    const state = useContext(AppContext);

    function isEmpty(obj) {
		return Object.keys(obj).length === 0;
    }

    let carDetails = <div/>;

    // Check if we have a delivery and display contextual help if necessary
    if (!isEmpty(state.currentDelivery)) {
        carDetails = <DeliveryDetails delivery={state.currentDelivery} />;
    }

    return (
        <div>
            {carDetails}

            <HelpButtonList />

            <div className="Main-footer">
                <BackButton previousPage={state.previousPage} />
            </div>
        </div>
    );
}

export default Help;
