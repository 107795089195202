import React, {useContext} from "react";
import "./DeliveryDetails.css";
import constants from "../../constants";
import AppContext from "../state/AppContext";

/**
 * Single Car details page - display car details on every page
 * @param props Object
 * @returns {*}
 * @constructor
 */
function DeliveryDetails(props) {
    const state = useContext(AppContext);
    console.log("DeliveryDetails, Line 11 (props): ", props);
    const delivery = props.delivery;
    const deliveryImage = delivery.car ? delivery.car.image : delivery.parcelbox.image;
    const deliveryDestination = getDestination(delivery);

    return (
        <header
            className={`Delivery-details ${
                delivery.deliveryStatus === constants.DELIVERY_STATUS.COMPLETED
                    ? `Background-green`
                    : ``
            } ${
                delivery.deliveryStatus === constants.DELIVERY_STATUS.FAILED
                    ? `Background-red`
                    : ``
            } ${
                state.currentPage === constants.PAGES.DELIVERY_LIST
                    ? `Margin-bottom`
                    : ``
            }`}
        >
            <div className="Customer-details">
                <p>{delivery.customerName}</p>
                <p>
                    {delivery.houseNumber} {delivery.streetName}
                </p>
                <p>{delivery.postcode}</p>
                <p>{delivery.clientName}</p>
                <p>{delivery.barcode}</p>
            </div>
            <div className="Car-details">
                <img className="Car-image" src={deliveryImage} alt=""/>
                <p>
                    {deliveryDestination.make} {deliveryDestination.modelName} -{" "}
                    {deliveryDestination.regNumber}
                </p>
            </div>
        </header>
    );
}

export default DeliveryDetails;

/**
 * Handle non-cars gracefully
 * @param delivery
 * @returns {Object}
 */
function getDestination(delivery) {
	return {
		make: delivery.car ? delivery.car.make : delivery.parcelbox.make,
		modelName: delivery.car ? delivery.car.modelName : "",
		regNumber: delivery.car ? delivery.car.regNumber : ""
	};
}