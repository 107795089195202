import React, { useState } from "react";
import AppContext from "./AppContext";

function GlobalState(props) {
	const [currentUser, setCurrentUser] = useState(props.initialState.currentUser);
    const [currentPage, setCurrentPage] = useState(props.initialState.currentPage);
    const [previousPage, setPreviousPage] = useState(props.initialState.previousPage);
    const [currentDelivery, setCurrentDelivery] = useState(props.initialState.currentDelivery);
    const [deliveries, setDeliveries] = useState(props.initialState.deliveries);
    const [errorMessage, setErrorMessage] = useState();
    const [courierMessage, setCourierMessage] = useState();
    const [currentCommandId, setCommandId] = useState();

	const updateCurrentUser = (user) => {
		setCurrentUser(user);
	};

    const updateCourierMessage = (courierMessage) => {
    	setCourierMessage(courierMessage);
	};

    const updateCurrentPage = (page) => {
    	// console.log('Class: GlobalState, Line 27 (page): ', page);
        setCurrentPage(page);
    };

    const updatePreviousPage = (page) => {
        setPreviousPage(page);
    };

	const updateCurrentDelivery = (delivery) => {
		setCurrentDelivery(delivery);
	};

	const updateCurrentCommandId = (id) => {
		setCommandId(id);
	};

	/**
	 * Instead of using a loop, we already know the current delivery
	 * just modify the status directly
	 * @param deliveryStatus
	 */
    const updateCurrentDeliveryStatus = (deliveryStatus) => {
		const delivery = currentDelivery;
		delivery.deliveryStatus = deliveryStatus;
		updateCurrentDelivery(delivery);
    };

    const updateErrorMessage = (errorMessage) => {
        setErrorMessage(errorMessage);
    };

    return (
        <AppContext.Provider
            value={{
                previousPage: previousPage,
                currentUser: currentUser,
                currentPage: currentPage,
                currentDelivery: currentDelivery,
                deliveries: deliveries,
                errorMessage: errorMessage,
				courierMessage: courierMessage,
				currentCommandId: currentCommandId,
                updateCurrentPage: updateCurrentPage,
                updateCurrentDelivery: updateCurrentDelivery,
                updateCurrentDeliveryStatus: updateCurrentDeliveryStatus,
                updatePreviousPage: updatePreviousPage,
				updateCurrentCommandId: updateCurrentCommandId,
				updateCourierMessage: updateCourierMessage,
                setErrorMessage: setErrorMessage,
				setCourierMessage: setCourierMessage,
				setDeliveries: setDeliveries
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export default GlobalState;