import React from "react";
import "./Loading.css";
import ClipLoader from "react-spinners/ClipLoader";

function Loading() {
    return (
        <div className="Loading-container">
            <div className="Loading">
                <ClipLoader size={80} color={"#3db38d"} />
            </div>
        </div>
    );
}

export default Loading;
