import React from "react";
import { Auth } from "aws-amplify";
import "./SignOutButton.css";

async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log("error signing out: ", error);
    }
    //changes the url location back to the home screen
    window.location.replace("/");
}
// className="Sign-out-button"
function SignOutButton() {
    return (
        <button className="Sign-out-confirmation-buttons" onClick={signOut}>
            Yes
        </button>
    );
}

export default SignOutButton;
