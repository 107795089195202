import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import DeliveryDetails from "../display/DeliveryDetails";
import DriverStepList from "../display/DriverStepList";
import UnlockButton from "../display/buttons/UnlockButton";
import BackButton from "../display/buttons/BackButton";
import Loading from "../display/Loading";
import constants from "../../constants";

function Scanned() {
    const state = useContext(AppContext);
    //the driverStepListOrLoadingPage state switches between DriverStepList and a Loading component. The Loading component works as a dummy/temporary component due to the lock button taking a few seconds to change the UI state to unocking. This fixes a few seconds of nothing changing on the screen.
    const [activateDummyLoadingPage, setActivateDummyLoadingPage] = React.useState(false);

    if (activateDummyLoadingPage) {
        return (
            <div>
                <DeliveryDetails delivery={state.currentDelivery} />
                <div>
                    <Loading />
                    <p className="Lock-status-p">{constants.LABELS.UNLOCKING}</p>
                </div>

                <div className="Main-footer"></div>
            </div>
        );
    } else {
        return (
            <div>
                <DeliveryDetails delivery={state.currentDelivery} />
                <DriverStepList />

                <div className="Main-footer">
                    <UnlockButton activateDummyLoadingPage={activateDummyLoadingPage} setActivateDummyLoadingPage={setActivateDummyLoadingPage} />
                    <BackButton activateDummyLoadingPage={activateDummyLoadingPage} previousPage={constants.PAGES.DELIVERY_PAGE} />
                </div>
            </div>
        );
    }
}

export default Scanned;
