import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import DriverStepList from "../display/DriverStepList";
import DeliveryDetails from "../display/DeliveryDetails";
import LockButton from "../display/buttons/LockButton";
import constants from "../../constants";
import Loading from "../display/Loading";

/**
 * activateDummyLoadingPage is the boolean that turns true when someone clicks the unlock/lock button.
 * this triggers the driverStepListOrLoadingPage to the dummy loading page.
 * @returns {*}
 * @constructor
 */
function CloseAndLock() {
    const state = useContext(AppContext);
    const [activateDummyLoadingPage, setActivateDummyLoadingPage] = React.useState(false);

    if (activateDummyLoadingPage) {
        return (
            <div>
                <DeliveryDetails delivery={state.currentDelivery} />
                <div>
                    <Loading />
                    <p className="Lock-status-p">{constants.LABELS.LOCKING}</p>
                </div>

                <div className="Main-footer"/>
            </div>
        );
    } else {
        return (
            <div>
                <DeliveryDetails delivery={state.currentDelivery} />
                <DriverStepList />
                <div className="Main-footer">
                    <LockButton activateDummyLoadingPage={activateDummyLoadingPage} setActivateDummyLoadingPage={setActivateDummyLoadingPage} />
                </div>
            </div>
        );
    }
}

export default CloseAndLock;
