import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";

// Page Imports
import DeliveryList from "../display/DeliveryList";
import DeliveryPage from "./DeliveryPage";
import BarcodeScanner from "./BarcodeScanner";
import Scanned from "./Scanned";
import Unlocking from "./Unlocking";
import Unlocked from "./Unlocked";
import Locking from "./Locking";
import Locked from "./Locked";
import Help from "./Help";
import PlaceInBoot from "./PlaceInBoot";
import CheckLock from "./CheckLock";
import CloseAndLock from "./CloseAndLock";
import Error from "./error_pages/Error";
import Loading from "../display/Loading";
import CheckUnlock from "./CheckUnlock";

function PageRouter() {
    console.log("Inside PageRouter!");
    const state = useContext(AppContext);
    // console.log("state in page router: ", state);
    console.log("state in page router: ", state.currentDelivery);

    switch (state.currentPage) {
        case constants.PAGES.DELIVERY_LIST:
            return <DeliveryList />;
        case constants.PAGES.DELIVERY_PAGE:
            return <DeliveryPage />;
        case constants.PAGES.BARCODE_SCANNER:
            return <BarcodeScanner />;
        case constants.PAGES.SCANNED:
            return <Scanned />;
        case constants.PAGES.UNLOCKING:
            return <Unlocking />;
		case constants.PAGES.CHECK_UNLOCK:
			return <CheckUnlock />;
        case constants.PAGES.UNLOCKED:
            return <Unlocked />;
        case constants.PAGES.PLACE_IN_BOOT:
            return <PlaceInBoot />;
        case constants.PAGES.CLOSE_AND_LOCK:
            return <CloseAndLock />;
        case constants.PAGES.LOCKING:
            return <Locking />;
        case constants.PAGES.LOCKED:
            return <Locked />;
        case constants.PAGES.CHECK_LOCK:
            return <CheckLock />;
        case constants.PAGES.HELP:
            return <Help />;
        case constants.PAGES.ERROR:
            return <Error />;
        default:
            return <Loading />;
    }
}

export default PageRouter;
