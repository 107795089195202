import React from "react";

/**
 * @return {string}
 */
function GetLabelFromState(state) {
	console.log('Class: GetLabelFromState, Line 5 (st.cd): ', state.currentDelivery);

	if (state.hasOwnProperty('currentDelivery') && state.currentDelivery.car) {
		return "car"
	} else if (state.hasOwnProperty('currentDelivery') && state.currentDelivery.parcelbox) {
		return "box"
	} else { // should never get here
		return "car"
	}
}

export default GetLabelFromState;