import React, { useState } from "react";
import "./MessageModal.css";
import "./SignOutModal.css";
import SignOutButton from "./SignOutButton";

function MessageModal() {
    const [showModal, setShowModal] = React.useState(false);
    const showHideClassName = showModal ? "modal display-block" : "modal display-none";

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <>
            <button className="Modal-button" onClick={handleShow}>
                Sign Out
            </button>
            <div className={showHideClassName}>
                <section className="Sign-out-modal-main">
                    <p className="Sign-out-modal-message">Are you sure you want to sign out?</p>
                    <div className="Sign-out-modal-buttons-div">
                        <SignOutButton />
                        <button className="Sign-out-confirmation-buttons" onClick={handleClose}>
                            No
                        </button>
                    </div>
                </section>
            </div>
        </>
    );
}

export default MessageModal;
