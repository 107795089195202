import React, { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar } from 'react-bootstrap';
import './NavigationBar.css';
import HelpButton from './HelpButton';
import SignOutModal from './SignOutModal';

function NavigationBar() {
    return (
        <div>
            <Navbar
                className="Navbar"
                collapseOnSelect
                expand="lg"
                variant="dark"
            >
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={require('../../assets/EvriLogo.png')}
                        width="150px"
                        height="100%"
                        className="Navbar-brand d-inline-block align-center nav-logo"
                    />
                </Navbar.Brand>

                <Nav className="mr-auto"></Nav>

                <Nav.Link>
                    <HelpButton />
                </Nav.Link>
                <Nav.Link>
                    <SignOutModal />
                </Nav.Link>
            </Navbar>
        </div>
    );
}

export default NavigationBar;
