import React, {useContext} from "react";
import AppContext from "../../state/AppContext";
import constants from "../../../constants";
import axios from "axios";
import "./LockButton.css";
import {LoggedInContext} from "../../state/LoggedInContext";

const LOCK_URL = process.env.REACT_APP_VMS_LOCK_URL;

/**
 * @param props {Object}
 * @returns {*} Lock button
 * @constructor
 */
function LockButton(props) {
	const state = useContext(AppContext);
	const vehicleId = getVehicle(state);

	const { setIsSignedIn } = useContext(LoggedInContext);
	const [buttonDisabled, setButtonDisabled] = React.useState(false);

	async function lockCar() {
		state.updateCurrentDeliveryStatus(constants.DELIVERY_STATUS.LOCKING);

		await axios(`${LOCK_URL}/${vehicleId}/lock`, {
			method: "POST",
			data: JSON.stringify({
				refresh_token: getToken(state),
				make: getMake(state),
				id: state.currentDelivery.id,
				courierEmail: setIsSignedIn ? setIsSignedIn : state.currentDelivery.courierEmail,
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((res) => {
				console.log("Lock api success, res", res);
				state.updateCurrentCommandId(res.data.commandId);
				state.updateCurrentPage(constants.PAGES.LOCKING);
			})
			.catch((e) => {
				console.log("Lock api error: ", e);
				state.updateCurrentDeliveryStatus(constants.DELIVERY_STATUS.FAILED);
				state.updateCurrentPage(constants.PAGES.ERROR);
			});
	}

	return (
		<div>
			<button
				className={`Dark-blue-button ${props.activateDummyLoadingPage === true ? `Hidden-button` : ` ` }`}
				onClick={() => {
					setButtonDisabled(true);
					lockCar();
					props.setActivateDummyLoadingPage(true);
				}}
				disabled={buttonDisabled}>
				Lock
			</button>
		</div>
	);
}

export default LockButton;

function getToken(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.refreshToken :
		state.currentDelivery.parcelbox.refreshToken;
}

function getVehicle(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.vehicleId :
		state.currentDelivery.parcelbox.refreshToken;
}

function getMake(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.make :
		constants.DEFAULT_PARCEL_BOX_MAKE;
}
