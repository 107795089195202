import React from "react";
import axios from "axios";

const DELIVERY_SERVICE_URL = process.env.REACT_APP_DELIVERY_SERVICE_URL;

/**
 * Get user from Hermes API (DynamoDb)
 * reverting to .then because await's catch continues
 * @param loggedInUser
 * @returns {Array[]}
 * @constructor
 */
function GetTodaysDeliveries(loggedInUser) {
    const [error, setError] = React.useState();
    const [deliveries, setDeliveries] = React.useState([]);
    const [loadingDeliveries, setLoadingDeliveries] = React.useState(true);

    React.useEffect(() => {
        function fetchDeliveries() {
        	console.log('GetTodaysDeliveries: fetchDeliveries, Line 19 (URL): ', DELIVERY_SERVICE_URL);
            console.log("loggedinUser in fetchdeliveries is ", loggedInUser);
            axios(`${DELIVERY_SERVICE_URL}?courierEmail=${loggedInUser}`)
                .then((res) => {
                    console.log("res.data.Items: ", res.data.Items);
                    setDeliveries(res.data.Items);
                    setLoadingDeliveries(false);
                })
                .catch((error) => {
                    console.log("error in fetchDeliveries is :", error);
                    setError("Service is down");
                    setLoadingDeliveries(false);
                });
        }
        fetchDeliveries();
        setLoadingDeliveries(true);
    }, [loggedInUser]);

    return [deliveries, loadingDeliveries, error];
}

export default GetTodaysDeliveries;
