import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import DriverStepList from "../display/DriverStepList";
import DeliveryDetails from "../display/DeliveryDetails";
import axios from "axios";

const DELIVERY_SERVICE_URL = process.env.REACT_APP_DELIVERY_SERVICE_URL;

function CheckUnlock() {
    const state = useContext(AppContext);
    console.log('Class: CheckUnlock, Line 12 (TOP): ', state);

    async function updateDeliveryStatus(status) {
        const payload = {
            status: status,
        };
        const httpConfig = {
            method: "POST",
            data: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
            },
        };
        const URL = `${DELIVERY_SERVICE_URL}/${state.currentDelivery.id}`;
        axios(URL, httpConfig).catch((error) => {
            console.log("error in updateDeliveryStatus is :", error);
        });
    }

    async function handleStateUpdate(status) {
        const response = await updateDeliveryStatus(status);
        console.log('Class: handleStateUpdate, Line 33 (RESP): ', response);
        state.updateCurrentDeliveryStatus(status);
        state.updateCurrentPage(getNextPage(status));
    }

    return (
        <div>
            <DeliveryDetails delivery={state.currentDelivery} />
            <DriverStepList />

            <div className="Main-footer-checkLockFlow">
				<button className="Red-button" onClick={() => {handleStateUpdate(constants.DELIVERY_STATUS.FAILED); }}>
					No
				</button>

				<button className="Hidden-button" onClick={() => {
					handleStateUpdate(constants.DELIVERY_STATUS.UNLOCKED); }}>
				</button>

                <button className="Green-button" onClick={()=> {handleStateUpdate(constants.DELIVERY_STATUS.UNLOCKED);}}>
                    Yes
                </button>
            </div>
        </div>
    );
}

export default CheckUnlock;

function getNextPage(status) {
	return status === constants.DELIVERY_STATUS.FAILED ?
		constants.PAGES.DELIVERY_LIST :
		constants.PAGES.PLACE_IN_BOOT;
}