import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import DeliveryDetails from "../display/DeliveryDetails";
import constants from "../../constants";

function ClickableDetails(props) {
    const state = useContext(AppContext);

    function loadDelivery() {
        state.updateCurrentDelivery(props.delivery);
        state.updatePreviousPage(state.currentPage);
        state.updateCurrentPage(constants.PAGES.DELIVERY_PAGE);
    }

    return (
        <div className="approved" onClick={loadDelivery}>
            <DeliveryDetails delivery={props.delivery}></DeliveryDetails>
        </div>
    );
}

export default ClickableDetails;
