import React, { useContext } from "react";
import AppContext from "../../state/AppContext";
import { Button } from "react-bootstrap";
import constants from "../../../constants.js";

const axios = require("axios").default;
const LOGGING_URL = process.env.REACT_APP_VMS_LOGGING_URL;
const DELIVERY_SERVICE_URL = process.env.REACT_APP_DELIVERY_SERVICE_URL;

function HelpLoggingButton(props) {
    const state = useContext(AppContext);

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const vehicleId = getVehicle(state);
    const refresh_token = getToken(state);

    function failLogRedirect() {
        logHelpMessage();
        if (!isEmpty(state.currentDelivery) && props.eventType === "FAILED") {
            console.log("failed current delivery");
            failCurrentDelivery();
            state.updateCurrentDeliveryStatus(constants.DELIVERY_STATUS.FAILED);
        }
        state.updateCurrentDelivery({});
        state.updateCurrentPage(constants.PAGES.DELIVERY_LIST);
        props.close(); //closes the modal
    }

    function failCurrentDelivery() {
        const payload = {
            status: constants.DELIVERY_STATUS.FAILED,
            id: state.currentDelivery.id,
        };
        const httpConfig = {
            method: "POST",
            data: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json",
            },
        };
        const URL = `${DELIVERY_SERVICE_URL}/${state.currentDelivery.id}`;
        axios(URL, httpConfig).catch((error) => {
            console.log("error in updateDeliveryStatus from HelpLoggingButton:", error);
        });
    }

    async function logHelpMessage() {
        const URL = `${LOGGING_URL}/${vehicleId}/logging`;
        if (props.failType) {
            // wont make the call unless there is information to be logged
            await axios(URL, {
                method: "POST",
                data: JSON.stringify({
					make: getMake(state),
                    refresh_token: refresh_token,
                    id: state.currentDelivery.id,
                    barcode: state.currentDelivery.barcode,
                    eventType: props.eventType,
                    failType: props.failType,
                    failReason: props.reason,
                    pageOfOrigin: state.previousPage,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
			.then((res) => {
				console.log("Logged help message: ", res);
			})
			.catch((e) => {
				console.log("Error logging help page: ", e);
			});
        }
    }

    return (
        <div>
            <Button variant={props.variant} onClick={failLogRedirect}>
                {props.children}
            </Button>
        </div>
    );
}

export default HelpLoggingButton;

function getToken(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.refreshToken :
		state.currentDelivery.parcelbox.refreshToken;
}

function getVehicle(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.vehicleId :
		state.currentDelivery.parcelbox.refreshToken;
}

function getMake(state) {
	return state.hasOwnProperty('currentDelivery') && state.currentDelivery.car ?
		state.currentDelivery.car.make :
		constants.DEFAULT_PARCEL_BOX_MAKE;
}