import React, {useContext} from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import "./BarcodeScanner.css";
import "./error_pages/Error.css";
import DeliveryDetails from "../display/DeliveryDetails";
import MessageModal from "../display/MessageModal";
import BarCodeScanner from 'barcode-react-scanner';

/**
 * Using ScandIt to scan 2 types of barcode, current and new Strategic barcode
 * On successful scan, moves on to the next page
 * On unsuccessful scan, will show modal with error message
 * Depends on state.currentDelivery
 * @returns {*}
 */
function BarcodeScanner() {
	const state = useContext(AppContext);
	const currentDelivery = state.currentDelivery;
	const [scanError, setScanError] = React.useState(false);
	const [showModal, setShowModal] = React.useState(false);

	function handleScan(response) {
		console.log('Class: handleScan, Line 24 (res): ', response);
		console.log('Class: handleScan, Line 25 (bcd): ', currentDelivery.barcode);
		if (response === currentDelivery.barcode) {
			state.updateCurrentPage(constants.PAGES.SCANNED);
		} else {
			handleError();
		}
	}

	function handleBackButton() {
		state.updateCurrentPage(constants.PAGES.DELIVERY_PAGE);
	}

	function handleError() {
		console.log("BarcodeScanner: handleError, Line 45 (error): ", scanError);
		setScanError(true);
		setShowModal(true);
	}

	return (
		<div className="scan-container">
			<div>
				<DeliveryDetails delivery={currentDelivery}/>
			</div>

			<BarCodeScanner onUpdate={(err, resp) => {
				if (resp) {
					handleScan(resp.getText())
				}
			}} id="scan-barcode" className="scan-barcode-area">
				<MessageModal
					message={constants.BARCODE_ERROR_MESSAGE}
					showModal={showModal}
					hideModal={() => setShowModal(false)}
				/>
			</BarCodeScanner>

			<div className="Main-footer">
				<button className="Dark-blue-button" onClick={handleBackButton}>
					Back
				</button>
			</div>
		</div>
	);
}

export default BarcodeScanner;