import "bootstrap/dist/css/bootstrap.css";
import "./RefreshLocation.css";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

function RefreshLocation(props) {
    let loadingButton = (
        <div className="Refresh-button">
            <FontAwesomeIcon
                onClick={props.onRefreshButtonClick}
                icon={faRedo}
            />
        </div>
    );

    // While GPS is loading don't run onRefreshButtonClick again
    if (props.loadingCarGPS) {
        loadingButton = (
            <div className="Refresh-button">
                <FontAwesomeIcon icon={faRedo} />{" "}
            </div>
        );
    }

    return (
        <div className="row Refresh-location">
            <div className="col-10 Location-updated">
                <p>Location</p>
            </div>
            <div className="col-2 Refresh-Button">{loadingButton}</div>
        </div>
    );
}

export default RefreshLocation;
