import React, { useContext } from "react";
import AppContext from "../state/AppContext";
import constants from "../../constants";
import Loading from "../display/Loading";
import GetUnlockStatus from "../api/GetUnlockStatus";
import DeliveryDetails from "../display/DeliveryDetails";
import CheckUnlock from "./CheckUnlock";

function Unlocking() {
	const state = useContext(AppContext);

	const [carUnlockStatus, carUnlockStatusLoading] = GetUnlockStatus();

	if (carUnlockStatusLoading) {
		return (
			<div>
				<DeliveryDetails delivery={state.currentDelivery} />
				<Loading />
				<p className="Lock-status-p">{constants.LABELS.UNLOCKING}</p>
				<div>
					<div className="Main-footer"/>
				</div>
			</div>
		);
	} else {
		state.updateCurrentPage(constants.PAGES.CHECK_UNLOCK);
		return <CheckUnlock />;
	}
}

export default Unlocking;