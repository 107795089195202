import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const MapContainer = (props) => {
    console.log("MAP: ", props.carGPS.carLocation);
    console.log("COURIER POS: ", props.courierGPS);

    const mapStyles = {
        height: "calc(100vh - 369px)",
        width: "100%",
    };

    const options = {
        draggable: true,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
    };

    const courierPosition = {
        lat: props.courierGPS.latitude,
        lng: props.courierGPS.longitude,
    };

    const carPosition = {
        lat: props.carGPS.carLocation.latitude,
        lng: props.carGPS.carLocation.longitude,
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyDmVbMw76a6cyiqvgMcI6dbz33M8hGNFKc">
            <GoogleMap mapContainerStyle={mapStyles} options={options} zoom={16} mapTypeId={"satellite"} center={courierPosition}>
                <Marker position={courierPosition} label={"You"} />
                <Marker position={carPosition} label={props.label} />
            </GoogleMap>
        </LoadScript>
    );
};

export default MapContainer;
